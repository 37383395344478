import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Container from '~components/Container';
import Link from '~components/Link';
import RichText from '~components/RichText';
import RoundedLinkButton from '~components/buttons/RoundedLinkButton/RoundedLinkButton';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import { getSubscribeButtonProps } from '~utils/buttontext';
import useConversionFramework from '~hooks/useConversionFramework';
import SectionCard from './SectionCard';
import MobileView from './MobileView';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '60px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '16px'
    }
  },
  contentContainer: {
    height: '100%',
    marginTop: '120px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '60px'
    }
  },
  heroContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  heroTitle: {
    width: '100%',
    textAlign: 'center',
    color: '#000000',
    fontSize: 64,
    lineHeight: '100%',
    fontFamily: 'Roboto',
    fontWeight: '500',
    maxWidth: '973px',
    marginTop: '7px',
    marginBottom: '64px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '35px',
      marginBottom: '36px'
    }
  },
  heroImage: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: props => (props.hasMobileImage ? 'fit-content' : '100%')
    }
  },
  heroDescription: {
    maxWidth: 895,
    textAlign: 'center',
    '& > *': {
      fontFamily: 'Roboto',
      fontSize: '32px',
      fontWeight: 500,
      color: '#000000',
      lineHeight: '128%',
      margin: '177px 0px 52px 0px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '18px',
        margin: '27px 0px 40px 0px'
      }
    },
    '& > p:empty': {
      height: '14px'
    }
  },
  buttonArea: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    marginBottom: '20px',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '60px'
    }
  },
  sections: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    rowGap: 64,
    marginBottom: 100,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 50
    }
  },
  subtitles: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 15,
    marginBottom: '40px',
    width: '100%',
    borderTop: '1px rgba(0, 0, 0, 0.15) solid',
    borderBottom: '1px rgba(0, 0, 0, 0.15) solid',
    padding: '53px 0px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      rowGap: 30
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      padding: '36px 0px 16px',
      borderTop: 'none',
      borderBottom: 'none'
    }
  },
  subtitle: {
    minWidth: 300,
    width: '32%',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '8px',
      width: '100%'
    },
    '& > *': {
      fontFamily: 'Inter',
      fontSize: '20px',
      fontWeight: 600,
      color: '#959495',
      lineHeight: '128%',
      margin: '0px 0px 8px',
      maxWidth: 363,
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        fontWeight: 500
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
        margin: 'auto'
      }
    },
    '& > p:empty': {
      height: '14px'
    },
    '& > * > strong': {
      color: '#222222'
    },
    '& > * > a': {
      color: '#959495',
      textDecoration: 'underline'
    }
  },
  mobileSubtitleContainer: {
    marginTop: 60
  },
  mobileSubtitle: {
    maxWidth: '341px',
    margin: 'auto',
    '& > *': {
      marginTop: 0,
      marginBottom: 0,
      color: '#000000',
      fontSize: 32,
      fontFamily: 'Inter',
      fontWeight: '600',
      lineHeight: '130%',
      textAlign: 'center'
    }
  },
  ctaLink: {
    color: '#158EFF',
    fontSize: 22,
    fontFamily: 'Roboto',
    fontWeight: '500',
    marginTop: -32,
    marginBottom: 64,
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
      marginTop: -16,
      marginBottom: 32
    }
  }
}));

const PreviewLibrary = props => {
  const {
    title,
    mobile_title,
    mobile_subtitle,
    subtitles,
    cta_text,
    cta_path,
    library_sections,
    bottom_shape_divider
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

  const { isMarketingSite } = useConversionFramework();
  const { jwtAvailable, marketingUser } = useUserContext().session;

  const {
    text: subscribeBtnText,
    url: subscribeBtnUrl,
    shouldTrackAnalytics: shouldCtaButtonTrackAnalytics,
    analyticsEvent: ctaAnalyticsEvent
  } = getSubscribeButtonProps(jwtAvailable, isMarketingSite, marketingUser);

  const renderSubtitle = useCallback(() => {
    if (subtitles?.length) {
      return (
        <div className={classes.subtitles}>
          {subtitles.map(subtitle => (
            <RichText
              html={subtitle?.html}
              externalClassName={classes.subtitle}
              verticalSpacing={0}
            />
          ))}
        </div>
      );
    }

    return null;
  }, [subtitles, classes.subtitles, classes.subtitle]);

  return (
    <div className={classes.container}>
      <Container className={classes.contentContainer}>
        <div className={classes.heroContainer}>
          <h1 className={classes.heroTitle}>{isMobile ? mobile_title || title : title}</h1>
          {cta_path && cta_text && (
            <Link to={cta_path} className={classes.ctaLink} underline="none">
              {cta_text}
            </Link>
          )}
          {!isMobile && renderSubtitle()}

          {!isMobile ? (
            <div className={classes.sections}>
              {library_sections.map((section, index) => (
                <SectionCard key={section?.title} {...section} index={index} />
              ))}
            </div>
          ) : (
            <MobileView cards={library_sections} />
          )}

          <div className={classes.buttonArea}>
            <RoundedLinkButton
              text={subscribeBtnText}
              to={subscribeBtnUrl}
              shouldTrackAnalytics={shouldCtaButtonTrackAnalytics}
              analyticsEvent={ctaAnalyticsEvent}
              analyticsTitle={title}
              analyticsSection="Preview Library"
              size="small"
              variant="black"
            />
          </div>

          {isMobile && (
            <div className={classes.mobileSubtitleContainer}>
              {mobile_subtitle?.html && (
                <RichText
                  html={mobile_subtitle?.html}
                  externalClassName={classes.mobileSubtitle}
                  verticalSpacing={0}
                />
              )}
              {renderSubtitle()}
            </div>
          )}
        </div>
      </Container>
      {bottom_shape_divider?.url && (
        <BottomShapeDivider src={bottom_shape_divider.url} bgColor="transparent" flex />
      )}
    </div>
  );
};

PreviewLibrary.propTypes = {
  title: PropTypes.string,
  mobile_title: PropTypes.string,
  mobile_subtitle: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string
  }),
  subtitles: PropTypes.arrayOf(
    PropTypes.shape({
      html: PropTypes.string,
      text: PropTypes.string
    })
  ),
  cta_text: PropTypes.string,
  cta_path: PropTypes.string,
  library_sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      see_more_path: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          custom_resource_title: PropTypes.string,
          custom_path: PropTypes.string,
          resource_title: PropTypes.string,
          resource_path: PropTypes.string
        })
      )
    })
  ),
  bottom_shape_divider: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  })
};

PreviewLibrary.defaultProps = {
  title: '',
  mobile_title: '',
  mobile_subtitle: null,
  cta_text: '',
  cta_path: '',
  library_sections: [],
  bottom_shape_divider: null,
  subtitles: []
};

export default PreviewLibrary;
