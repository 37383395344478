import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Link from '~components/Link';
import { getPlaceholderFormattedUrl } from '~utils/url-helper';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: '0px 16px 0px 0px',
    width: '32%',
    minWidth: '300px',
    [theme.breakpoints.down(1000)]: {
      width: '48%'
    },
    [theme.breakpoints.down(700)]: {
      width: '100%'
    }
  },
  title: {
    color: '#01153D',
    fontSize: 32,
    fontFamily: 'Inter',
    fontWeight: '600'
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    maxWidth: '315px'
  },
  itemLink: {
    color: '#000000',
    fontSize: 20,
    fontFamily: 'Roboto',
    textDecoration: 'none',
    lineHeight: '140%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16
    }
  },
  seeMoreLink: {
    color: '#158EFF',
    fontSize: 20,
    fontFamily: 'Roboto',
    fontWeight: '500',
    marginTop: '16px'
  }
}));

const SectionCard = ({ title, see_more_path, items }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h3 className={classes.title}>{title}</h3>
      <div className={classes.itemContainer}>
        {items.map(item => (
          <Link
            className={classes.itemLink}
            to={getPlaceholderFormattedUrl(item?.custom_path || item?.resource_path)}
          >
            {item?.custom_resource_title || item?.resource_title}
          </Link>
        ))}
      </div>
      <Link className={classes.seeMoreLink} to={getPlaceholderFormattedUrl(see_more_path)}>
        {'See more >'}
      </Link>
    </div>
  );
};

SectionCard.propTypes = {
  title: PropTypes.string.isRequired,
  see_more_path: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      custom_path: PropTypes.string,
      custom_resource_title: PropTypes.string,
      resource_path: PropTypes.string,
      resource_title: PropTypes.string
    })
  )
};

SectionCard.defaultProps = {
  see_more_path: '',
  items: []
};

export default SectionCard;
