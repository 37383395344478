import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Link from '~components/Link';
import { getPlaceholderFormattedUrl } from '~utils/url-helper';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    backgroundColor: '#F5F5F7',
    borderRadius: 20,
    padding: '27px 28px',
    width: '100%',
    height: '100%'
  },
  title: {
    color: '#01153D',
    fontSize: 20,
    fontFamily: 'Roboto',
    fontWeight: '700',
    marginTop: 0
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    maxWidth: '250px'
  },
  itemLink: {
    color: '#000000',
    fontSize: 14,
    lineHeight: '135%',
    fontFamily: 'Roboto',
    fontWeight: '400'
  },
  seeMoreLink: {
    color: '#158EFF',
    fontSize: 14,
    fontFamily: 'Roboto',
    fontWeight: '700',
    marginTop: '16px'
  }
});

const Card = ({ title, see_more_path, mobile_cap, items }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h3 className={classes.title}>{title}</h3>
      <div className={classes.itemContainer}>
        {items.slice(0, mobile_cap).map(item => (
          <Link
            className={classes.itemLink}
            to={getPlaceholderFormattedUrl(item?.custom_path || item?.resource_path)}
          >
            {item?.custom_resource_title || item?.resource_title}
          </Link>
        ))}
      </div>
      <Link className={classes.seeMoreLink} to={getPlaceholderFormattedUrl(see_more_path)}>
        {'See more >'}
      </Link>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  see_more_path: PropTypes.string,
  mobile_cap: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      custom_path: PropTypes.string,
      custom_resource_title: PropTypes.string,
      resource_path: PropTypes.string,
      resource_title: PropTypes.string
    })
  )
};

Card.defaultProps = {
  see_more_path: '',
  mobile_cap: 4,
  items: []
};

export default Card;
