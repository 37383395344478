import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Card from './Card';
import BaseSlider from './BaseSlider';

const MobileView = ({ cards }) => {
  const sliderRef = useRef();

  return (
    <BaseSlider ref={sliderRef}>
      {cards.map(card => (
        <Card {...card} key={card.title} />
      ))}
    </BaseSlider>
  );
};

MobileView.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      see_more_path: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          custom_resource_title: PropTypes.string,
          custom_path: PropTypes.string,
          resource_title: PropTypes.string,
          resource_path: PropTypes.string
        })
      )
    })
  )
};

MobileView.defaultProps = {
  cards: []
};

export default MobileView;
